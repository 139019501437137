/* CSS */
import "@js/parts/css";

/* JS */
import "@js/parts/lazyloading";
import "@js/parts/old_js";

const formSuccess = () => {
  let formAll = document.querySelector('form');
  let successContainerAll = document.querySelector('#successTwo')
  formAll.addEventListener('freeform-render-success', (event) => {
    event.preventDefault();
    
    let freeformAll = event.freeform;
    let successAll = document.createElement('div');
    successAll.classList.add(freeformAll.options.successClassBanner);
    successAll.appendChild(document.createTextNode( '{{ category.form.successMessage }} kaktuuuuussss' ));

    successContainerAll.classList.add('wrapper', 'container', 'px-4', 'py-4', 'bg-blue', 'rounded-xl', 'my-12');
    successContainerAll.appendChild(successAll);
  });
}

const getAnchors = () => {
  const headers = document.querySelectorAll("h2");
  let anchors = [];

  headers.forEach((e) => {
    e.id = Math.random();
    anchors.push({
      id: e.id,
      title: e.innerText,
    });
  });
  /* console.log(headers) */
  return anchors;
};

const scrollAnchor = (elmId) => {
  const offset = 150;
  const elm = document.getElementById(elmId);
  // const position = elm.offsetTop;
  const position = getOffsetTop(elm);

  const targetPosition = position - offset;

  window.scrollTo({
    top: targetPosition,
    left: 0,
    behavior: "smooth",
  });
  /* console.log('targetPos', targetPosition) */
};

function getOffsetTop(el, parent = window) {
  let offsetTop = 0;
  do {
    if (!isNaN(el.offsetTop) && el != parent) {
      offsetTop += el.offsetTop;
    }
  } while ((el = el.offsetParent));
  return offsetTop;
}

            
/* const hoverDots = (e) => {
  // Get Menu Mouse leave
  let menuRight = document.getElementById('menuRight')
  menuRight.addEventListener('mouseleave', () => {
    console.log(menuRight)
    console.log('leave menu')
    menuRight.classList.remove('menuRightHover')
  })
  // mouse on add class for hover
  e.addEventListener('mouseover', () => {
    console.log('mouseover')
    menuRight.classList.add('menuRightHover')
  });
}
 */

const hoverDots = (e) => {
  // Get Menu Mouse leave
  let menuRight = document.getElementById('menuRight')
  e.addEventListener('mouseleave', () => {
    /* console.log(menuRight)
    console.log('leave menu') */
    menuRight.classList.remove('menuRightHover')
  })
  // mouse on add class for hover
  e.addEventListener('mouseover', () => {
    /* console.log('mouseover') */
    menuRight.classList.add('menuRightHover')
  });
}


/**
 * Alpine JS
 * Best to put it last so that all other potential JS is available
 * when components start getting initialized.
 */
import Alpine from 'alpinejs';
window.Alpine = Alpine;
Alpine.start();

/**
 * Accept HMR as per: https://vitejs.dev/guide/api-hmr.html
 */
if (import.meta.hot) {
  import.meta.hot.accept(() => {
    console.log("HMR");
  });
}


